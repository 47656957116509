import React from "react";

import logo from "../assets/logo.svg";

const Logout = (props) => {

  const url = new URLSearchParams(props.location.search).get('endUrl')
  localStorage.removeItem('loggedIn')
  const domainsQuery = new URLSearchParams(props.location.search).get('domains')
  const domains = domainsQuery.split(',')
  if (domains.length === 1) {
    const nextUrl  = domains[0] === 'cimpress' ? 'https://staging.developer.cimpress.io' : (domains[0] === 'vpsvc' ? 'https://auth0-log-event-table.dev.login.vpsvc.com' :  'https://dev588.d1a490sngo38c.amplifyapp.com')
    window.location.replace(`${nextUrl}/logout?endUrl=${url}`)
  } else {
    const nextUrl  = domains[0] === 'cimpress' ? 'https://staging.developer.cimpress.io' : (domains[0] === 'vpsvc' ? 'https://auth0-log-event-table.dev.login.vpsvc.com' :  'https://dev588.d1a490sngo38c.amplifyapp.com')
    window.location.replace(`${nextUrl}/logout?endUrl=${url}&domains=${domains[1]}`)
  }  
  // 
  return(
    <div className="text-center hero my-5">
      <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
      <h1 className="mb-4">React.js Sample Project</h1>
  
      <p className="lead">
        This is a sample application that demonstrates an authentication flow for
        an SPA, using <a href="https://reactjs.org">React.js</a>
      </p>
    </div>
  )
};

export default Logout;
